.socials-container h1 {
    padding-top: 200px;
    margin: 0px;
    color: white;
}

.socials-container h2 {
    color: white;
    font-size: 20px;
    margin: 120px auto 40px;
}

.social-btn-container {
    margin: auto;
    width: 100%;
    max-width: 600px;
}

.social-btn {
    margin: 10px auto;
    width: 100%;
    border-radius: 6px;
    border: none;
}

.social-btn h4 {
    display: inline;
    float: left;
    position: relative;
    left: 5px;
    font-size: 11px;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
}

.social-btn img {
    height: 25px;
    position: relative;
    float: right;
    top: 8px;
    right: 5px;
}

@media (min-width: 300px) {
    .social-btn {
        margin: 10px auto;
        width: 100%;
        border-radius: 6px;
        border: none;
    }
    
    .social-btn h4 {
        display: inline;
        float: left;
        position: relative;
        left: 10px;
        font-size: 12px;
        color: white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    }
    
    .social-btn img {
        height: 25px;
        position: relative;
        float: right;
        top: 10px;
        right: 10px;
    }
}

@media (min-width: 400px) {
    .social-btn {
        margin: 10px auto;
        width: 100%;
        border-radius: 6px;
        border: none;
    }
    
    .social-btn h4 {
        display: inline;
        float: left;
        position: relative;
        left: 10px;
        font-size: 14px;
        color: white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    }
    
    .social-btn img {
        height: 30px;
        position: relative;
        float: right;
        top: 12px;
        right: 10x;
    }
}

.car-img-1 {
    display: block;
    width: 100%;
    margin: 50px auto 0px;
    position: relative;
    height: 280px;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/bmw1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.links-container {
    width: 90%;
    margin: 150px auto;
    max-width: 800px;
}

.appt-container h2 {
    color: white;
    font-size: 32px;
    margin: 0px auto 40px;
}
.appt-container p {
    color: white;
    font-size: 18px;
    text-align: left;
    margin: 20px auto;
    width: 90%;
}


@media (min-width: 500px) {
    .car-img-1 {
        background-attachment: fixed;
        height: 400px;
    }

    .social-btn-container {
        width: 90%;
    }
    
}

@media (min-width: 1000px) {
    .links-container {
        width: 90%;
        margin: 150px auto;
        max-width: 800px;
    }

    .socials-container h2 {
        font-size: 42px;
        margin: 140px auto 50px;
    }
    
    .socials-container p {
        font-size: 20px;
        text-align: left;
        margin: 30px auto;
        width: 90%;
    }

    .appt-container h2 {
        color: white;
        font-size: 32px;
        margin: 0px auto 40px;
    }
    .appt-container p {
        color: white;
        font-size: 18px;
        text-align: left;
        margin: 20px auto;
        width: 90%;
    }
}

.ig {
    background-image: linear-gradient(45deg, #B14700, #7A0094, #2f005e);
}
.ig:hover {
    cursor: pointer;
    background-image: linear-gradient(45deg, #d15400, #9400b6, #430086);
}

.yt {
    background: #B10000;
}
.yt:hover {
    cursor: pointer;
    background: #c90000;
}

.tt {
    background: black;
}
.tt:hover {
    cursor: pointer;
    background: #161616;
}

.fb {
    background: #2955BB;
}
.fb:hover {
    cursor: pointer;
    background: #3165df;
}