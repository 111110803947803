.about-us-container h1 {
    padding-top: 200px;
    margin: 0px;
    color: white;
}


.ab-image {
    margin: 70px 0px 50px;
    height: 300px;
    background-size: cover;
    background-position: top;
}

.team-1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/stp-team1.jpg");
}
.team-2 {
    margin: 50px auto;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/stp-team2.jpg");
}

.about-us-container {
    margin: auto;
    background: #1f1f1f;
    color: white;
    text-shadow: 0px 2px 2px black;
}

.about-us-container h2 {
    color: white;
    font-size: 32px;
    margin: 120px auto 40px;
}

.about-us-container p {
    color: white;
    font-size: 18px;
    text-align: left;
    margin: 20px auto;
}

.ab-text {
    margin: auto;
    width: 90%;
    max-width: 800px;
    margin-bottom: 120px;
}

.appt-container h2 {
    color: white;
    font-size: 32px;
    margin: 0px auto 40px;
}
.appt-container p {
    color: white;
    font-size: 18px;
    text-align: left;
    margin: 20px auto;
    width: 90%;
}


@media (min-width: 1000px) {
    .about-us-container h2 {
        font-size: 42px;
        margin: 140px auto 50px;
    }
    
    .about-us-container p {
        font-size: 20px;
        text-align: left;
        margin: 30px auto;
        width: 90%;
    }

    .ab-image {
        margin: 100px auto 0px;
        height: 800px;
        background-attachment: fixed;
        background-size: cover;
        background-position: top;
    }
}