.form-input {
    margin: 10px auto;
    display: block;
    width: 90%;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    padding: 10px 8px;
    background: #1f1f1f;
    color: white;
    border: 1px solid white;
}


.submit-btn {
    width: 95%;
    max-width: 500px;
    padding: 10px;
    font-size: 20px;
    background: #409800;
    color: white;
    border: none;
    border-radius: 6px;
}

.submit-btn:hover {
    cursor: pointer;
    background: #4ab300;
}

.sending {
    background: #272727;
    pointer-events: none;
}

.sent {
    background: #1a1a1a;
    box-shadow: 0px 0px 0px 2px #409800;
    opacity: 0.6;
    pointer-events: none;
}

@media (min-width: 1000px) {
    .form-input {
        margin: 10px auto;
        display: block;
        width: 90%;
        font-size: 18px;
        border: none;
        border-radius: 6px;
        padding: 10px 8px;
        background: rgba(0, 0, 0, 0.4);
        color: white;
        box-shadow: 0px 0px 0px 2px white;
    }
}

.error {
    border: 1px solid red;
}

.year, .make, .model {
    display: inline-block;
    margin: 0px 4px;
}

.year {
    width: 14%;
}

.make {
    width: 20%;
}

.model {
    width: 34%;
}

@media (min-width: 305px) {
    .year {
        width: 15%;
    }
    
    .make {
        width: 22%;
    }
    
    .model {
        width: 34%;
    }
}

@media (min-width: 400px) {
    .year {
        width: 15%;
    }
    
    .make {
        width: 25%;
    }
    
    .model {
        width: 36%;
    }
}

@media (min-width: 500px) {
    .year {
        width: 15%;
    }
    
    .make {
        width: 25%;
    }
    
    .model {
        width: 39%;
    }
}