.mobile-only {
    display: block;
}
.desktop-only {
    display: none;
}

.navbar-container {
    width: 100%;
    background: #1a1a1a;
    position: fixed;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 10000;
}

.navbar-logo {
    margin: auto;
    height: 35px;
    padding: 10px 0px;
}

.navbar-logo img {
    height: 100%;
}

.menu-btn,
.menu-exit-btn {
    height: 56px;
    width: 56px;
    position: fixed;
    top: 0px;
    right: 0px;
    transition: 200ms;
}

.menu-btn:hover,
.menu-exit-btn:hover {
    cursor: pointer;
    background: #2a2a2a;
}

.menu-rect,
.menu-exit-rect-1,
.menu-exit-rect-2 {
    background: white;
    width: 32px;
    height: 3px;
    margin: 9px auto;
    pointer-events: none;
}

.background-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.menu-container {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 85%;
    max-width: 280px;
    background: #1a1a1a;
    transition: 200ms;
}

.menu-exit-rect-1 {
    position: relative;
    top: 18px;
    transform: rotate(45deg);
}
.menu-exit-rect-2 {
    position: relative;
    top: 6px;
    transform: rotate(-45deg);
}

.menu-hide {
    right: -300px;
    transition: 200ms;
}

.menu-item {
    background: #1a1a1a;
    padding: 15px 30px;
}

.menu-item-container {
    margin-top: 56px;
}

.menu-item:hover {
    background: #2a2a2a;
    cursor: pointer;
}

.menu-item h3 {
    font-size: 24px;
    color: white;
    text-align: left;
}

.menu-item-container a {
    text-decoration: none;
}

.menu-item-active {
    background: #2a2a2a;
}

@media (min-width: 1000px) {
    .mobile-only {
        display: none;
    }
    .desktop-only {
        display: block;
    }

    .nav-width {
        width: 95%;
        max-width: 1400px;
        height: 56px;
        margin: auto;
    }

    .nav-img-container {
        display: inline-block;
        width: 30%;
    }

    .nav-item-container {
        float: right;
        width: 70%;
        height: 56px;
        text-align: right;
    }

    .navbar-logo {
        margin: 0px;
        text-align: left;
    }

    .menu-item {
        display: inline-block;
        padding: 3px 48px;
    }

    .menu-item h3 {
        text-align: center;
        font-size: 16px;
    }
}



.hidden {
    display: none;
}