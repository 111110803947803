.desktop-only {
    display: none;
}

.home-container h1 {
    padding-top: 200px;
    margin: 0px;
    color: white;
}

.page-container {
    max-width: 500px;
    margin: auto;
    background: #1f1f1f;
    margin-top: 120px;
    color: white;
    text-shadow: 0px 2px 2px black;
}

.page-container h2,
.address-container h2,
.services-container h2,
.appt-container h2 {
    color: white;
    font-size: 18px;
    margin: 0px auto 40px;
}

.page-container p,
.address-container p,
.services-container p,
.appt-container p {
    color: white;
    font-size: 14px;
    text-align: left;
    margin: 20px auto;
    width: 90%;
}

@media (min-width: 300px) {
    .page-container h2,
    .address-container h2,
    .services-container h2,
    .appt-container h2 {
        color: white;
        font-size: 24px;
        margin: 0px auto 40px;
    }

    .page-container p,
    .address-container p,
    .services-container p,
    .appt-container p {
        color: white;
        font-size: 18px;
        text-align: left;
        margin: 20px auto;
        width: 90%;
    }
}

@media (min-width: 400px) {
    .page-container h2,
    .address-container h2,
    .services-container h2,
    .appt-container h2 {
        color: white;
        font-size: 26px;
        margin: 0px auto 40px;
    }

    .page-container p,
    .address-container p,
    .services-container p,
    .appt-container p {
        color: white;
        font-size: 18px;
        text-align: left;
        margin: 20px auto;
        width: 90%;
    }
}

.page-btn {
    width: 90%;
    padding: 8px;
    margin: 20px auto;
    background: #409800;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
}

.page-btn:hover {
    cursor: pointer;
    background: #4ab300;
}

.car-img-1 {
    display: block;
    width: 100%;
    margin: 50px auto 0px;
    position: relative;
    height: 280px;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/bmw1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.address-container {
    margin: 50px auto;
}

.loc-hrs-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.add-hours-container {
    margin: auto;
    margin-bottom: 220px;
    width: 90%;
}

.add-hours-container p {
    font-size: 13px;
}

@media (min-width: 400px) {
    .add-hours-container {
        margin: auto;
        margin-bottom: 220px;
        width: 90%;
    }
    
    .add-hours-container p {
        font-size: 16px;
    }
}

.add-hours-left {
    display: block;
    float: left;
    width: 25%;
}

.add-hours-right {
    display: block;
    float: right;
    width: 75%;
}

.add-hours-right p {
    text-align: right;
}

.map-img iframe {
    height: 400px;
    width: 100%;
}

.services-container {
    width: 90%;
    max-width: 500px;
    margin: auto;
}

.services-container p {
    margin-bottom: 50px;
}

.service-img {
    height: 200px;
    margin: 30px auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.service-img h3 {
    font-size: 32px;
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
}

.appt-container {
    width: 95%;
    margin: auto;
    max-width: 500px;
}

@media (min-width: 1000px) {
    .desktop-only {
        display: block;
    }

    .address-container {
        margin: 0px;
        width: 100%;
        max-width: 100%;
        height: 570px;
    }

    .loc-hrs-container {
        margin-top: 50px;
        display: inline-block;
        width: 50%;
        max-width: 600px;
    }

    .map-img {
        width: 50%;
        float: right;
    }

    .map-img iframe {
        height: 570px;
    }

    .services-container {
        width: 100%;
        max-width: 100%;
        margin: 70px auto 0px;
    }
    
    .services-container p {
        width: 90%;
        max-width: 600px;
        margin-bottom: 50px;
    }
    
    .service-img {
        display: inline-block;
        height: 300px;
        width: 40%;
        margin: 30px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    }
    
    .service-img h3 {
        font-size: 32px;
        text-align: center;
        position: relative;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* width: 100%; */
        color: white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
    }

    .appt-text {
        display: inline-block;
        position: relative;
        bottom: 120px;
        width: 40%;
        max-width: 500px;
        margin-left: 20px;
        margin-right: 30px;
    }

    .form-container {
        margin: 200px 0px;
        display: inline-block;
        width: 50%;
        max-width: 500px;
    }
    
    .appt-container {
        width: 100%;
        max-width: 100%;
        margin: auto;
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url("../images/bmw1.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }

    .page-container {
        max-width: 800px;
        margin: auto;
        background: #1f1f1f;
        margin-top: 140px;
        text-shadow: 0px 2px 2px black;
    }
    
    .page-container h2,
    .address-container h2,
    .services-container h2 {
        font-size: 40px;
        margin: 0px auto 50px;
    }
    
    .page-container p,
    .address-container p,
    .services-container p,
    .appt-container p {
        font-size: 18px;
        text-align: left;
        margin: 30px auto;
        width: 90%;
    }
    
    .page-btn {
        width: 90%;
        padding: 16px;
        margin: 20px auto 60px;
        background: #409800;
        border: none;
        border-radius: 6px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
        color: white;
        font-size: 24px;
    }
    
    .page-btn:hover {
        cursor: pointer;
        background: #4ab300;
    }

    .loc-hrs-container h2 {
        font-size: 36px;
        margin-bottom: 20px;
    }

    .loc-hrs-container p {
        font-size: 18px;
    }

    .add-hours-container p {
        font-size: 17px;
    }
}




/* Services Images */


.service-1 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/engine-maint.jpg");
}
.service-2 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/custom-builds.jpg");
}
.service-3 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/aftermkt.jpg");
}
.service-4 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/detail.jpg");
}

.service-1,
.service-2,
.service-3,
.service-4 {
    background-size: cover;
    background-position: center;
}
