.footer-container {
    width: 100%;
    background: #111;
}

.footer-container h6 {
    color: #1f1f1f;
    font-size: 8px;
    padding: 20px;
    margin: 50px auto 0px;
    text-align: left;
    text-shadow: none;
}

@media (min-width: 300px) {
    .footer-container h6 {
        font-size: 9px;
        padding: 25px;
    }
}

@media (min-width: 400px) {
    .footer-container h6 {
        font-size: 10px;
        padding: 25px;
    }
}

@media (min-width: 1000px) {
    .footer-container h6 {
        font-size: 12px;
        margin: 0px auto;
    }
}