.hero-text {
    text-align: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
}

.hero-text h1 {
    font-size: 22px;
    font-style: italic;
}

.hero-text h4 {
    letter-spacing: 4px;
    font-size: 10px;
}

.home-container .hero-text h4 {
    letter-spacing: 2px;
    font-size: 9px;
}

.home-container .home-hero-title {
    color: #6CFF00;
    margin-left: 6px;
}

@media (min-width: 300px) {
    .hero-text h1 {
        font-size: 24px;
        font-style: italic;
    }

    .home-container .hero-text h4 {
        letter-spacing: 3px;
        font-size: 10px;
    }
}

@media (min-width: 400px) {
    .hero-text h1 {
        font-size: 32px;
        font-style: italic;
    }
    
    .hero-text h4 {
        letter-spacing: 8px;
        font-size: 11px;
    }

    .home-container .hero-text h4 {
        letter-spacing: 4px;
        font-size: 11px;
    }

    .home-container .home-hero-title {
        margin-left: 8px;
    }
}

.hero-image {
    position: relative;
    top: 55px;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.hero-btn {
    width: 90%;
    max-width: 350px;
    margin-top: 10px;
    padding: 6px;
    border: none;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 14px;
    box-shadow: 0px 0px 0px 1px #6CFF00, 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.hero-btn:hover {
    cursor: pointer;
    background: black;
    box-shadow: 0px 0px 0px 2px #6CFF00, 0px 4px 6px rgba(0, 0, 0, 0.6);
}

@media (min-width: 600px) {
    .hero-text {
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .hero-text h1 {
        font-size: 50px;
    }
    
    .hero-text h4 {
        letter-spacing: 10px;
        font-size: 12px;
    }
    
    .hero-image {
        height: 350px;
        background-attachment: fixed;
        background-position: top;
        background-size: contain;
    }
    
    .hero-btn {
        max-width: 430px;
        padding: 8px;
        font-size: 18px;
    }

    .home-container .hero-text h4 {
        letter-spacing: 7px;
        font-size: 11px;
    }

    .home-container .home-hero-title {
        margin-left: 12px;
    }
}

@media (min-width: 800px) {
    .hero-text {
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .hero-text h1 {
        font-size: 50px;
    }
    
    .hero-text h4 {
        letter-spacing: 10px;
        font-size: 12px;
    }
    
    .hero-image {
        height: 350px;
        background-attachment: fixed;
        background-position: top;
        background-size: contain;
    }
}

@media (min-width: 1000px) {
    .hero-text {
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .hero-text h1 {
        font-size: 80px;
    }
    
    .hero-text h4 {
        letter-spacing: 19px;
        font-size: 16px;
    }
    
    .hero-image {
        height: 600px;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
    }
    
    .hero-btn {
        max-width: 680px;
        padding: 12px;
        font-size: 24px;
    }

    .home-container .hero-text h4 {
        letter-spacing: 13px;
        font-size: 16px;
    }

    .home-container .home-hero-title {
        margin-left: 20px;
    }
}

/* Hero Images */

.bmw-rear {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/bmw-rear.jpg");
}
.car-repair {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/car-repair.jpg");
}
.cars-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/cars-hero.jpg");
}
